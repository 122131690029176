<template>
  <div class="flex items-center space-x-2">
    <AvatarGroup :hover-animation="accountList.length > 1">
      <Avatar
        v-for="account in accountList"
        size="xs"
        :key="account.id"
        :src="account.profile_picture_url"
        :class="{ 'cursor-pointer': accountList.length > 1 }"
        @click="selectAccount(account._id)"
      />
    </AvatarGroup>

    <Dropdown :trigger-text="instagramAdapter.activeProfile?.username" trigger-class="w-[200px]" body-wrapper-class="w-[200px]" placement="bottom-start">
      <template #body>
        <DropdownItem class="flex items-center space-x-2" v-for="account in accountList" :key="account.id" @click="selectAccount(account._id)">
          <Avatar size="xs" :src="account.profile_picture_url" />
          <span class="ml-2">{{ account.username }}</span>
        </DropdownItem>
      </template>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
  import { AvatarGroup, Avatar, Dropdown, DropdownItem } from '@tiny-ideas-ir/lib-vue-components/components.ts';
  import { useInstagramAdapterStore } from '~/stores/instagram-adapter';

  const instagramAdapter = useInstagramAdapterStore();

  const accountList = computed(() => {
    return [...instagramAdapter.profiles.filter((p) => p.isDefault), ...instagramAdapter.profiles.filter((p) => !p.isDefault)];
  });

  function selectAccount(_id: string) {
    if (_id === instagramAdapter.activeProfile?.id) return;
    instagramAdapter.switchAccount(_id);
  }
</script>
